// FilterSidebar.js
import React from 'react';

const FilterCheckbox = ({ label, options, selectedValues, onChange }) => {
  return (
    <div className='filter-section'>
      <h4 className='filter-section-title'>{label}</h4>
      {options.map((option) => (
        <label key={option.value} className='filter-section-label'>
          <input type='checkbox' value={option.value} checked={selectedValues.includes(option.value)} onChange={onChange} />
          {option.label}
        </label>
      ))}
    </div>
  );
};

const FilterInput = ({ label, type, value, onChange, placeholder }) => {
  return (
    <div className='filter-section'>
      <label className='filter-section-label'>
        {placeholder}:
        <input type={type} placeholder={placeholder} value={value} onChange={onChange} />
      </label>
    </div>
  );
};

const FilterResponsable = ({ value, onChange }) => {
  return (
    <div className='filter-section'>
      <h3 className='filter-section-title'>Responsable:</h3>
      <label className='filter-section-label'>
        <input type='text' placeholder='Buscar por responsable...' value={value} onChange={onChange} />
      </label>
    </div>
  );
};

const FilterSidebar = ({
  selectedTypes,
  handleTypeChange,
  selectedStatuses,
  handleStatusChange,
  searchEmail,
  handleEmailChange,
  searchName,
  handleNameChange,
  searchResponsable,
  handleResponsableChange,
}) => {
  return (
    <div className='filter-sidebar'>
      <h3>Filtros</h3>

      <FilterCheckbox
        label='Soporte'
        options={[
          { value: 'SOPORTE EMPLEADO', label: 'EMPLEADO' },
          { value: 'SOPORTE MANAGER', label: 'MANAGER' },
        ]}
        selectedValues={selectedTypes}
        onChange={handleTypeChange}
      />

      <FilterCheckbox
        label='Estado'
        options={[
          { value: 'Backlog', label: 'Backlog' },
          // {
          //   value: 'SelectedForDevelopment',
          //   label: 'Seleccionado para desarrollo',
          // },
          { value: 'InProgress', label: 'En curso' },
          { value: 'Finalized', label: 'Finalizada' },
        ]}
        selectedValues={selectedStatuses}
        onChange={handleStatusChange}
      />
      <h3 className='filter-section-title'>Creador por:</h3>
      <FilterInput label='Email' type='email' value={searchEmail} onChange={handleEmailChange} placeholder='Buscar por email' />
      <FilterInput label='Nombre' type='text' value={searchName} onChange={handleNameChange} placeholder='Buscar por nombre' />
      <FilterResponsable value={searchResponsable} onChange={handleResponsableChange} />
    </div>
  );
};

export default FilterSidebar;
