import React from 'react';
import { useLocation } from 'react-router-dom';
import { TicketsContactUsInfo } from './ticketsContactUsInfo';
import ContactUsEmailAnswer from './contactUsEmailAnswer';
import ContactUsInbentaInfo from './contactUsInbentaInfo';
import '../../styles/contactUsButton/detailsTicketsContactUs.css';

// Componente que muestra los tickets enviados a contacta con nosotros una vez que se selecciona uno de ellos, se muestra la información del ticket y la respuesta que se le ha dado.
const TicketDetail = ({
  handleFilterSearcher,
  filterInSearcher,
  autocompleteArticles,
  setHandleUpperCategoryIdParam,
  userInfoSSFF,
}) => {
  const location = useLocation();
  const { ticket } = location.state || {};

  if (!ticket) {
    return <p>No se encontraron detalles del ticket.</p>;
  }
  console.log(ticket);

  return (
    <div className='ticket-detail-container'>
      <div className='ticket-detail-table-details'>
        <TicketsContactUsInfo
          ticket={ticket}
          handleFilterSearcher={handleFilterSearcher}
          filterInSearcher={filterInSearcher}
          autocompleteArticles={autocompleteArticles}
          setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
          userInfoSSFF={userInfoSSFF}
        />
      </div>

      <div className='ticket-detail-answer-tittle'>RESPUESTA</div>
      <div className='ticket-detail-answer'>
        <div className='ticket-detail-answer-email'>
          <ContactUsEmailAnswer ticket={ticket} />
        </div>
        <div className='ticket-detail-answer-inbenta'>
          <ContactUsInbentaInfo
            handleFilterSearcher={handleFilterSearcher}
            filterInSearcher={filterInSearcher}
            autocompleteArticles={autocompleteArticles}
            setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
            userInfoSSFF={userInfoSSFF}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketDetail;
