import React, { useState } from 'react';
import { EditPopup } from './editPopUp';
import EditPencil from '../../../images/jira/pencil.svg';
import ContactUsEmailAnswer from './contactUsEmailAnswer';
//import ContactUsInbentaInfo from './contactUsinbentaInfo';
export const TicketsContactUsInfo = ({ ticket, userInfoSSFF, filterInSearcher, handleUpperCategoryIdParam }) => {
  //console.log('ticket que recibo a info', ticket);
  /* const [showQueueSelect, setShowQueueSelect] = useState(false);
  const [showResponsibleInput, setShowResponsibleInput] = useState(false);
  const [showStatusSelect, setShowStatusSelect] = useState(false);
  const [showPrioritySelect, setShowPrioritySelect] = useState(false);*/

  const [selectedSummary, setSelectedSummary] = useState(
    ticket.fields.summary.includes('SOPORTE MANAGER')
      ? 'Manager'
      : ticket.fields.summary.includes('SOPORTE EMPLEADO')
      ? 'Empleado'
      : ticket.fields.summary
  );
  const [newResponsible, setNewResponsible] = useState(ticket.fields.assignee ? ticket.fields.assignee.displayName : '');
  const [selectedStatus, setSelectedStatus] = useState(ticket.fields.status.name);
  const [selectedPriority, setSelectedPriority] = useState(ticket.fields.priority.name);
  const [showPopup, setShowPopup] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('es-ES', optionsDate);

    // CORRECCIÓN: eliminamos el espacio extra después de '2-digit'
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = date.toLocaleTimeString('es-ES', optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatDate(ticket.fields.updated);

  // Handlers para cambios
  /* const handleQueueChange = (event) => setSelectedQueue(event.target.value);
  const handleResponsibleChange = (event) =>
    setNewResponsible(event.target.value);
  const handleStatusChange = (event) => setSelectedStatus(event.target.value);
  const handlePriorityChange = (event) =>
    setSelectedPriority(event.target.value);*/
  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  // Función para cerrar el popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className='ticket-details-container'>
      <h2 className='ticket-list-title'>
        {ticket.id} - {ticket.fields.summary}
      </h2>
      {/*<div className='ticket-filters'>
        <div className='ticket-button-queue'>
          <button onClick={() => setShowQueueSelect(!showQueueSelect)}>
            Cambiar Cola
          </button>
          {showQueueSelect && (
            <select value={selectedQueue} onChange={handleQueueChange}>
              <option value='Soporte General'>Soporte General</option>
              <option value='Soporte Técnico'>Soporte Técnico</option>
              <option value='Soporte Empleado'>Soporte Empleado</option>
            </select>
          )}
        </div>

         Botón para cambiar el responsable 
        <div className='ticket-button-responsible'>
          <button
            onClick={() => setShowResponsibleInput(!showResponsibleInput)}
          >
            Cambiar Responsable
          </button>
          {showResponsibleInput && (
            <input
              type='text'
              value={newResponsible}
              onChange={handleResponsibleChange}
              placeholder='Nuevo responsable'
            />
          )}
        </div>

        {/* Botón para cambiar el estado 
        <div className='ticket-button-state'>
          <button onClick={() => setShowStatusSelect(!showStatusSelect)}>
            Cambiar Estado
          </button>
          {showStatusSelect && (
            <select value={selectedStatus} onChange={handleStatusChange}>
              <option value='Abierto'>Abierto</option>
              <option value='En Proceso'>En Proceso</option>
              <option value='Cerrado'>Cerrado</option>
            </select>
          )}
        </div>

        {/* Botón para cambiar la prioridad 
        <div className='ticket-button-priority'>
          <button onClick={() => setShowPrioritySelect(!showPrioritySelect)}>
            Cambiar Prioridad
          </button>
          {showPrioritySelect && (
            <select value={selectedPriority} onChange={handlePriorityChange}>
              <option value='Alta'>Alta</option>
              <option value='Media'>Media</option>
              <option value='Baja'>Baja</option>
            </select>
          )}
        </div>
      </div>*/}
      <div className='table-container-details'>
        <table className='tickets-table-details'>
          <thead>
            <tr classname='ticketsTable-tr'>
              <th classname='ticketsTable-th'>Id</th>
              <th classname='ticketsTable-th'>Creado por</th>
              <th classname='ticketsTable-th'>Soporte</th>
              <th classname='ticketsTable-th'>Responsable</th>
              <th classname='ticketsTable-th'>Estado</th>
              <th classname='ticketsTable-th'>Prioridad</th>
              <th classname='ticketsTable-th'>Creado el</th>
              <th classname='ticketsTable-th'>Editar</th>
            </tr>
          </thead>
          <tbody>
            <tr classname='ticketsTable-tr'>
              <td classname='ticketsTable-td'>{ticket.id}</td>
              <td classname='ticketsTable-td'>
                <p>{ticket.fields.customfield_10319}</p>
                <p>{ticket.fields.customfield_10318}</p>
              </td>
              <td classname='ticketsTable-td'>{selectedSummary}</td>
              <td classname='ticketsTable-td'>{newResponsible || 'Sin asignar'}</td>
              <td classname='ticketsTable-td'>{selectedStatus}</td>
              <td classname='ticketsTable-td'>
                {selectedPriority === 'Alta' ? (
                  <span className='priority-high'>Alta</span>
                ) : selectedPriority === 'Media' ? (
                  <span className='priority-medium'>Media</span>
                ) : (
                  <span className='priority-low'>Baja</span>
                )}
              </td>
              <td classname='ticketsTable-td'>
                <p>{formattedDate}</p>
                <p>{formattedTime}</p>
              </td>
              <td classname='ticketsTable-td'>
                <button className='edit-pencil-button' onClick={handleOpenPopup}>
                  <img className='img-edit-pencil' src={EditPencil} alt='Edit' />
                </button>
                {showPopup && <EditPopup ticket={ticket} handleClosePopup={handleClosePopup} />}
              </td>
            </tr>

            {/* Segunda fila especial con la columna que ocupa todo el ancho */}
            <tr classname='ticketsTable-tr'>
              <td classname='ticketsTable-td' colSpan='8' className='especialTD'>
                <p>Para: preguntanos@personas.telefonica.com</p>
              </td>
            </tr>
            <tr classname='ticketsTable-tr'>
              <td classname='ticketsTable-td' colSpan='9' className='content-details'>
                {/* <h2>{ticket.fields.summary}</h2> */}
                <p>Jurídica:{ticket.fields.customfield_10322}</p>
                <p>Área:{ticket.fields.customfield_10347}</p>
                <p>BU:{ticket.fields.customfield_10348}</p>
                <p>Departamento:{ticket.fields.customfield_10349}</p>
                <p>Contenido:{ticket.fields.customfield_10350}</p>
              </td>
            </tr>
            {/*   < tr classname='ticketsTable-tr' colSpan='9' className='especialTD'>
              <div className='ticket-detail-answer'>RESPUESTA</div>
            </tr>
            < tr classname='ticketsTable-tr' colSpan='10' className='especialTD'>
              <div className='ticket-detail-answer-email'>
                <ContactUsEmailAnswer ticket={ticket} />
              </div>
              <div className='ticket-detail-answer-inbenta'>
                {/*  <ContactUsInbentaInfo
                  filterInSearcher={filterInSearcher}
                  handleUpperCategoryIdParam={handleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
              </div>
            </tr> */}
          </tbody>
        </table>
      </div>
      <div className='ticket-detail-popUp'>
        {showPopup && (
          <EditPopup
            ticket={ticket}
            selectedSummary={selectedSummary}
            newResponsible={newResponsible}
            selectedStatus={selectedStatus}
            selectedPriority={selectedPriority}
            setSelectedSummary={setSelectedSummary}
            setNewResponsible={setNewResponsible}
            setSelectedStatus={setSelectedStatus}
            setSelectedPriority={setSelectedPriority}
            onClose={handleClosePopup}
          />
        )}
      </div>
    </div>
  );
};
