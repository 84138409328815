import React, { useState } from 'react';

// En ticket Details hay un popup que permite editar los tickets, este componente es el popup que se muestra al dar click en el botón de editar (lapicero).
export const EditPopup = ({
  ticket,
  selectedSummary,
  newResponsible,
  selectedStatus,
  selectedPriority,
  setSelectedSummary,
  setNewResponsible,
  setSelectedStatus,
  setSelectedPriority,
  onClose,
}) => {
  const [Summary, setSummary] = useState(selectedSummary);
  const [responsible, setResponsible] = useState(newResponsible);
  const [status, setStatus] = useState(selectedStatus);
  const [priority, setPriority] = useState(selectedPriority);

  const handleSave = () => {
    // Guardar los cambios en el estado principal
    setSelectedSummary(Summary);
    setNewResponsible(responsible);
    setSelectedStatus(status);
    setSelectedPriority(priority);
    onClose(); // Cerrar popup al guardar
  };

  return (
    <div className='popup'>
      <div className='popup-content'>
        <h3>Editar Ticket - {ticket.id}</h3>
        <div className='edit-field'>
          <label>Soporte</label>
          <select value={Summary} onChange={(e) => setSummary(e.target.value)}>
            <option value='Manager'>Manager</option>
            <option value='Empleado'>Empleado</option>
          </select>
        </div>
        <div className='edit-field'>
          <label>Responsable</label>
          <input
            type='text'
            value={responsible}
            onChange={(e) => setResponsible(e.target.value)}
            placeholder='Nuevo responsable'
          />
        </div>
        <div className='edit-field'>
          <label>Estado</label>
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value='Abierto'>Abierto</option>
            <option value='En Proceso'>En Proceso</option>
            <option value='Cerrado'>Cerrado</option>
          </select>
        </div>
        <div className='edit-field'>
          <label>Prioridad</label>
          <select
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
          >
            <option value='Alta'>Alta</option>
            <option value='Media'>Media</option>
            <option value='Baja'>Baja</option>
          </select>
        </div>
        <div className='popup-buttons'>
          <button onClick={handleSave}>Guardar</button>
          <button onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};
