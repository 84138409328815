import React from 'react';
import { Link } from 'react-router-dom';

//styles
import '../styles/bubbles_categories.css';

//images
import nominaBeneficios from '../../images/bubbles/nomina.png';
import formacionDesarrollo from '../../images/bubbles/formacion.png';
import herramientas from '../../images/bubbles/Herramientas.png';
import vacaciones from '../../images/bubbles/Vacaciones.png';
import solicitudes from '../../images/bubbles/Solicitudes.png'; // Pendiente de que desaparezca de imbenta como categoría
import desarrollo from '../../images/bubbles/Desarrollo.png';
import talento from '../../images/bubbles/Talento.png';
import equipo from '../../images/bubbles/Equipo.png';
import consultasManager from '../../images/bubbles/manager.png'; // Pendiente de que desaparezca de imbenta como categoría
// import roles from '../../images/bubbles/roles.jpg' // Pendiente de hacer cambios en imbenta e incorporar Roels como categoría.
import elipse355 from '../../images/ellipse355.png';

// Mapeo de imágenes de categorías por nombre
const categoryImageMap = {
  'Nómina y Beneficios': nominaBeneficios,
  'Mi Desarrollo': formacionDesarrollo,
  'Herramientas y Políticas': herramientas,
  'Vacaciones y Permisos': vacaciones,
  'Desarrollo de mi Equipo': desarrollo,
  'Atracción y Retención de Talento': talento,
  'Compensación y Organización': equipo,
};

const Bubbles_categories = ({
  categories,
  setSelectedCategory,
  userInfoSSFF,
  extraInfoDescriptionCategories,
}) => {
  // Filtrar y ordenar las categorías por id
  //const filteredCategories = categories.filter((id) => id.id > 74 && id.id < 84).sort((id1, id2) => id1.id - id2.id);
  // const filteredCategories = categories.filter((category) =>category.parent === '133' && category.depth === 2 &&  ).sort((id1, id2) => id1.id - id2.id);

  const filteredCategories = () => {
    let filteredCategories;

    filteredCategories = categories.filter(
      (category) => category.depth === 2 && category.parent === '133'
    );

    if (
      (userInfoSSFF.custom15 === 'Y' && userInfoSSFF.custom03 === '1893') ||
      userInfoSSFF.custom03 === '1702' ||
      userInfoSSFF.custom03 === '1017'
    ) {
      filteredCategories = categories.filter(
        (category) => category.depth === 2 && category.parent === '133'
      );
    } else if (userInfoSSFF.custom15 === 'Y') {
      filteredCategories = filteredCategories.concat(
        categories.filter(
          (category) => category.depth === 2 && category.parent === '40'
        )
      );
    }

    return filteredCategories;
  };
  // Función para seleccionar aleatoriamente una imagen
  const getRandomCatImage = () => {
    const randomIndex = Math.floor(Math.random() * 10) + 1; //En random-categories hay 10 imágenes. Sumamos 1 para evitar que salga 0.
    return randomIndex;
  };

  return (
    <div className="categories" id="bubbles-categories">
      {/* Breadcrumb */}
      <div className="categories__breadcrumb">
        <img src={elipse355} alt="circle-icon" className="circle-icon" />
        <h4 className="categories__breadcrumb--title">
          PREGUNTAS Y RESPUESTAS
        </h4>
      </div>
      {/* Fin delBreadcrumb */}
      <div className="categories__container">
        {filteredCategories().map((category, index) => (
          <Link
            to="/subcategories"
            className="bubble__container"
            key={index}
            onClick={() =>
              // Cuando se hace clic en una burbuja de categoría, actualiza el estado del componente padre con la información de la categoría seleccionada. Esto permite al componente padre almacenar y utilizar esta información para mostrar subcategorías.
              setSelectedCategory({ name: category.name, id: category.id })
            }
          >
            <div className="bubble">
              <img
                src={
                  categoryImageMap[category.name] ||
                  require(`../../images/categories/random-categories/category${getRandomCatImage()}.jpg`)
                }
                alt={`categorie-${category.name}`}
              />
              <h2 className="bubble__title">{category.name}</h2>
              {category.parent === '40' && (
                <>
                  <div className="managerTag">
                    <p className="managerTag__text">Manager</p>
                  </div>
                </>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Bubbles_categories;
