import React, { useEffect, useState } from 'react';
import { getJiraTickets } from '../../../services/apiJira';
import { Link } from 'react-router-dom';
import '../../styles/contactUsButton/listTicketsContactUs.css';
import FilterSidebar from './contactUsFilterSideBar';

//ADAPTAR FILTROS PARA IMITAR FUNCIONALIDAD Y CÓDIGO DE historialTickets.js
const TicketList = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage] = useState(15);
  const [searchEmail, setSearchEmail] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchResponsable, setSearchResponsable] = useState('');

  // Se obtienen los tickets de Jira y se filtran para que aparezcan solo los de soporte empleado y manager
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const ticketList = await getJiraTickets();
        const initialFilteredTickets = ticketList.filter(
          (ticket) =>
            ticket.fields.summary === 'SOPORTE EMPLEADO' ||
            ticket.fields.summary === 'SOPORTE MANAGER'
        );
        setTickets(ticketList);
        setFilteredTickets(initialFilteredTickets);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchTickets();
  }, []);

  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const lowercasedSearchEmail = searchEmail.toLowerCase();
    const lowercasedSearchName = searchName.toLowerCase();
    const lowercasedSearchResponsable = searchResponsable.toLowerCase();

    const filteredByTypeAndSearchTerm = tickets
      .filter((ticket) =>
        selectedTypes.length > 0
          ? selectedTypes.includes(ticket.fields.summary)
          : ticket.fields.summary === 'SOPORTE EMPLEADO' ||
            ticket.fields.summary === 'SOPORTE MANAGER'
      )
      .filter((ticket) =>
        selectedStatuses.length > 0
          ? selectedStatuses.includes(ticket.fields.status.name)
          : true
      )
      .filter((ticket) => {
        const summary = ticket.fields.summary.toLowerCase();
        const email = (
          ticket.fields.customfield_10319 ? ticket.fields.customfield_10319 : ''
        ).toLowerCase();
        const name = (
          ticket.fields.customfield_10318 ? ticket.fields.customfield_10318 : ''
        ).toLowerCase();
        const responsable = (
          ticket.fields.assignee
            ? ticket.fields.assignee.displayName
            : 'Sin asignar'
        ).toLowerCase();
        const status = ticket.fields.status.name.toLowerCase();

        return (
          (summary.includes(lowercasedSearchTerm) ||
            email.includes(lowercasedSearchTerm) ||
            name.includes(lowercasedSearchTerm) ||
            responsable.includes(lowercasedSearchResponsable) ||
            status.includes(lowercasedSearchTerm)) &&
          email.includes(lowercasedSearchEmail) &&
          name.includes(lowercasedSearchName)
        );
      });

    setFilteredTickets(filteredByTypeAndSearchTerm);
    setCurrentPage(1);
  }, [
    searchTerm,
    searchEmail,
    searchName,
    searchResponsable,
    tickets,
    selectedTypes,
    selectedStatuses,
  ]);

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setSelectedTypes((prevSelectedTypes) =>
      prevSelectedTypes.includes(value)
        ? prevSelectedTypes.filter((type) => type !== value)
        : [...prevSelectedTypes, value]
    );
  };

  const handleStatusChange = (e) => {
    const value = e.target.value;
    setSelectedStatuses((prevSelectedStatuses) =>
      prevSelectedStatuses.includes(value)
        ? prevSelectedStatuses.filter((status) => status !== value)
        : [...prevSelectedStatuses, value]
    );
  };

  const handleEmailChange = (e) => setSearchEmail(e.target.value);
  const handleNameChange = (e) => setSearchName(e.target.value);
  const handleResponsableChange = (e) => setSearchResponsable(e.target.value);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const optionsDate = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedDate = date.toLocaleDateString('es-ES', optionsDate);

    const optionsTime = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    const formattedTime = date.toLocaleTimeString('es-ES', optionsTime);

    return { formattedDate, formattedTime };
  };

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredTickets.length / ticketsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  if (loading) {
    return <p className='loading-message'>Cargando tickets...</p>;
  }

  if (error) {
    return <p className='error-message'>Error al cargar tickets: {error}</p>;
  }

  return (
    <div className='ticket-list-container'>
      <div className='main-content'>
        <h2 className='ticket-list-title'>Historial</h2>
        <input
          type='text'
          className='ticket-search-input'
          placeholder='Buscar tickets...'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className='table-container'>
          <FilterSidebar
            selectedTypes={selectedTypes}
            handleTypeChange={handleTypeChange}
            selectedStatuses={selectedStatuses}
            handleStatusChange={handleStatusChange}
            searchEmail={searchEmail}
            handleEmailChange={handleEmailChange}
            searchName={searchName}
            handleNameChange={handleNameChange}
            searchResponsable={searchResponsable}
            handleResponsableChange={handleResponsableChange}
          />
          <table className='tickets-table'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Creado por</th>
                <th>Soporte</th>
                <th>Responsable</th>
                <th>Estado</th>
                <th>Pr</th>
                <th>Origen</th>
                <th>Últimos cambios</th>
                <th>SLA</th>
              </tr>
            </thead>
            <tbody>
              {currentTickets.map((ticket, index) => {
                const { formattedDate, formattedTime } = formatDate(
                  ticket.fields.updated
                );

                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {ticket.id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <p>{ticket.fields.customfield_10319}</p>
                          <p>{ticket.fields.customfield_10318}</p>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {ticket.fields.summary}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {ticket.fields.assignee
                            ? ticket.fields.assignee.displayName
                            : 'Sin asignar'}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {ticket.fields.status.name}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {ticket.fields.priority.name === 'Alta' ? (
                            <span className='priority-high'></span>
                          ) : (
                            <span className='priority-medium'></span>
                          )}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {ticket.fields.customfield_10322
                            ? ticket.fields.customfield_10322
                            : 'N/A'}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <p>{formattedDate}</p>
                          <p>{formattedTime}</p>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {ticket.fields.timeoriginalestimate
                            ? `${
                                ticket.fields.timeoriginalestimate / 3600
                              } horas`
                            : 'Sin SLA'}
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan='9' className='especialTD'>
                        <Link
                          to={`/form/ContactUsTickets/details`}
                          state={{ ticket }}
                          className='ticket-link'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <p>{ticket.fields.customfield_10350}</p>
                        </Link>
                      </td>
                    </tr>
                    <tr className='margin-row'>
                      <td colSpan='9'></td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='pagination'>
          {pageNumbers.map((number) => (
            <button key={number} onClick={() => setCurrentPage(number)}>
              {number}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TicketList;
