import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill'; // Instalar si no está: npm install react-quill
import 'react-quill/dist/quill.snow.css'; // Estilos predeterminados del editor


// Componente que permite enviar un correo electrónico como respuesta a un ticket de Jira, es como un simulador del envio de un correo electrónico, tan solo que habría que mandar como campos a jira el to, cc, bcc y el mensaje que se desea enviar.
const ContactUsEmailAnswer = ({ ticket }) => {
  const [formData, setFormData] = useState({
    to: ticket?.fields?.customfield_10319 || '',
    cc: '',
    bcc: '',
    message: '',
  });

  const [emailSent, setEmailSent] = useState(false);
  const [isToFieldComplete, setIsToFieldComplete] = useState(false);
  const [replyType, setReplyType] = useState('reply');

  useEffect(() => {
    setIsToFieldComplete(!!formData.to);
  }, [formData.to]);

  useEffect(() => {
    switch (replyType) {
      case 'reply':
        setFormData({
          ...formData,
          to: ticket?.fields?.customfield_10319 || '',
          message: '',
        });
        break;
      case 'replyAll':
        setFormData({
          ...formData,
          to: `${ticket?.fields?.customfield_10319 || ''}, ${
            ticket?.fields?.responsable || ''
          }`,
          message: '',
        });
        break;
      case 'forward':
        setFormData({
          ...formData,
          to: '',
          message: `\n\n--- Mensaje original ---\nDepartamento: ${ticket?.fields?.departamento}\nDivisión: ${ticket?.fields?.division}\nContenido: ${ticket?.fields?.contenido}`,
        });
        break;
      default:
        break;
    }
  }, [replyType, ticket]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleQuillChange = (value) => {
    setFormData({ ...formData, message: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailSent(true);
  };

  const handleClearToField = () => {
    setFormData({ ...formData, to: '' });
  };

  const handleClearAll = () => {
    setFormData({ to: '', cc: '', bcc: '', message: '' });
    setEmailSent(false);
  };

  return (
    <div className='form-container'>
      {emailSent ? (
        <div>
          <h3>¡Correo enviado!</h3>
          <p>Para: {formData.to}</p>
          <p>CC: {formData.cc}</p>
          <p>BCC: {formData.bcc}</p>
          <div dangerouslySetInnerHTML={{ __html: formData.message }} />
          <button onClick={handleClearAll} className='clear-button'>
            X
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Acciones:</label>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
              <button type='button' onClick={() => setReplyType('reply')}>
                Responder
              </button>
              <button type='button' onClick={() => setReplyType('replyAll')}>
                Responder a todos
              </button>
              <button type='button' onClick={() => setReplyType('forward')}>
                Reenviar
              </button>
            </div>
          </div>

          <div>
            <label>Para:</label>
            {isToFieldComplete ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    background: '#e0e0e0',
                    padding: '5px 10px',
                    borderRadius: '5px',
                  }}
                >
                  {formData.to}
                </div>
                <button
                  type='button'
                  onClick={handleClearToField}
                  className='clear-button'
                >
                  X
                </button>
              </div>
            ) : (
              <input
                type='email'
                name='to'
                value={formData.to}
                onChange={handleChange}
                required
              />
            )}
          </div>

          <div>
            <label>CC:</label>
            <input
              type='email'
              name='cc'
              value={formData.cc}
              onChange={handleChange}
            />
          </div>

          <div>
            <label>BCC:</label>
            <input
              type='email'
              name='bcc'
              value={formData.bcc}
              onChange={handleChange}
            />
          </div>

          <div>
            <label>Responder al mensaje:</label>
            <ReactQuill value={formData.message} onChange={handleQuillChange} />
          </div>

          <div className='button-container'>
            <button type='submit'>Enviar</button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactUsEmailAnswer;
